<template>
  <div class="member">
    <div class="member_ad">
      <img src="@/assets/img/icon/img_member_ad.png" alt="">
    </div>
    <div class="member_serve">
      <img class="member_serve_text" src="@/assets/img/icon/img_member_serve.png" alt="">
      <ul class="product_list">
        <li v-for="(item, index) in configData.vip_product" :class="cur_active_item.id== item.id?'active_item':''" @click="chooseVipType(item)"
          :key="index">
          <div class="vip_product_name">
            {{ item.name }}
          </div>
          <div class="product_time">
            <span class="member_serve_year">({{item.month}}个月)</span>
          </div>
          <div class="product_price">
            ￥<strong>{{ item.price }}</strong>
          </div>
          <div class="old_pirce">
            ￥ {{ item.old_price }}
          </div>
          <div class="product_tags">
            <span class="member_serve_month">每月仅需{{ item.single_price }}元</span><br>
          </div>
          <button class="member_serve_btn" @click="openVip(item.id)"
            v-if="$store.state.userInfo.vip.vip_level&&$store.state.userInfo.vip.vip_level!=0">立即升级会员</button>
          <button class="member_serve_btn" @click="openVip(item.id)" v-else>立即开通会员</button>
        </li>
      </ul>
    </div>
    <div class="member_equities">
      <img src="@/assets/img/icon/img_member_equities.png" alt="">
      <table>
        <thead>
          <tr>
            <th v-for="(item, index) in tableData.theadList" :key="index">{{ item }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData.tbodyList" :key="index">
            <td>{{ item.name }}</td>
            <td v-for="(config, idx) in item.child" :key="idx">
              <span v-if="config == 0">X</span>
              <span v-if="config == -1"></span>
              <span v-if="config > 0">{{ config }}条/天</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <el-dialog :visible.sync="payShow" width="30%" :before-close="closePayShow">
      <div class="weixin_pay_box">
        <div class="top_pay_title">
          <div class="pay_icon">
            <img src="../../../assets/img/icon/icon_weixin_pay.png" alt="">
          </div>
          <div class="pay_title">
            微信支付
          </div>
        </div>
        <div class="er_code_box">
          <img :src="payErCodeImg" alt="">
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" @click="comfirmPay">已完成支付</el-button> -->
        <el-button @click="closePayShow">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import QRCode from 'qrcode';

export default {
  data () {
    return {
      cur_active_item: {},
      payShow: false,
      payErCodeImg: '',
      tableData: {
        theadList: ['功能', '游客', '普通会员', '高级会员', '黄金会员', '钻石会员'],
        tbodyList: [{ name: '发布招标信息', key: 'publish_invitation', child: [] },
        { name: '发布中标信息', key: 'publish_win', child: [] },
        { name: '招标详情查看', key: 'invitation_scan', child: [] },
        { name: '中标详情查看', key: 'win_scan', child: [] },
        { name: '发布采招信息', key: 'publish_collection', child: [] },
        { name: '在线采招报价', key: 'collection_price', child: [] },
        { name: '查看采招电话', key: 'collection_phone', child: [] },
        { name: '发布招工信息', key: 'publish_employee', child: [] },
        { name: '获取找活简历电话', key: 'find_work_phone', child: [] },
        { name: '发布劳务分包', key: 'publish_package', child: [] },
        { name: '查看劳务电话', key: 'package_phone', child: [] },
        { name: '发布机械租售信息', key: 'publish_rent', child: [] },
        { name: '查看机械租售电话', key: 'rent_phone', child: [] },
        { name: '发布产品', key: 'publish_product', child: [] },
        { name: '查看产品电话', key: 'product_phone', child: [] }
        ]
      },
      configData: {
        member_vip: '', //会员信息，没有则是游客
        vip_config: [], //会员权限信息,权限单位是 条/天，-1则是无限制，0是无权限
        vip_product: [] //会员规格
      },
      timer: null
    }
  },
  created () {
    this.getVipConfigProduct();
  },
  methods: {
    getVipConfigProduct () {
      let arrObj = {
        publish_invitation: [],
        publish_win: [],
        invitation_scan: [],
        win_scan: [],
        publish_collection: [],
        collection_price: [],
        collection_phone: [],
        publish_employee: [],
        find_work_phone: [],
        publish_package: [],
        package_phone: [],
        publish_rent: [],
        rent_phone: [],
        publish_product: [],
        product_phone: []
      };
      this.$api.getVipConfigProduct().then(res => {
        console.log(res, '会员信息');
        this.configData = res.data;
        this.configData.vip_config.forEach(item => {
          const {
            publish_invitation,
            publish_win,
            invitation_scan,
            win_scan,
            collection_price,
            collection_phone,
            publish_employee,
            find_work_phone,
            publish_package,
            package_phone,
            publish_rent,
            rent_phone,
            publish_product,
            product_phone,
            publish_collection
          } = item;
          arrObj.publish_invitation.push(publish_invitation);
          arrObj.publish_win.push(publish_win);
          arrObj.invitation_scan.push(invitation_scan);
          arrObj.win_scan.push(win_scan);
          arrObj.publish_collection.push(publish_collection);
          arrObj.collection_price.push(collection_price);
          arrObj.collection_phone.push(collection_phone);
          arrObj.publish_employee.push(publish_employee);
          arrObj.find_work_phone.push(find_work_phone);
          arrObj.publish_package.push(publish_package);
          arrObj.package_phone.push(package_phone);
          arrObj.publish_rent.push(publish_rent);
          arrObj.rent_phone.push(rent_phone);
          arrObj.publish_product.push(publish_product);
          arrObj.product_phone.push(product_phone);
        });
        this.tableData.tbodyList.forEach(item => {
          this.$set(item, 'child', arrObj[item.key]);
        });
      })
    },
    chooseVipType (val) {
      this.cur_active_item = val;
    },
    openVip (vip_product_id) {
      this.$api.createOrder({ vip_product_id, pay_type: 'pc' }).then(orderRes => {
        console.log(orderRes, '当前会员支付');
        QRCode.toDataURL(orderRes.data.pay_info.code_url).then(codeRes => {
          console.log(codeRes, '二维码');
          this.payErCodeImg = codeRes;
          this.payShow = true;
          if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
          }
          this.timer = setInterval(() => {
            this.$api.checkOrderStatus({ order_id: orderRes.data.order_id }).then(payRes => {
              console.log('轮询查状态', payRes);
              if (payRes.data.pay_status == 1) {
                this.$message.success('支付成功!');
                clearInterval(this.timer);
                this.timer = null;
                setTimeout(() => {
                  location.reload();
                }, 1500)

              }
            })
          }, 2000)
        })
      })
    },
    comfirmPay () {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      location.reload();
    },
    closePayShow () {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.payShow = false;
    }
  }
}
</script>
  
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.member_ad img {
  width: 825px;
  height: 76px;
}

.member_serve {
  margin-top: -5px;
  background: linear-gradient(180deg, #e5eaff 0%, #ffffff 100%);
  border-radius: 10px;
  padding: 20px 24px;

  .member_serve_text {
    width: 146px;
    margin-bottom: 29px;
  }
}
.product_list {
  width: 777px;
  overflow-x: scroll;

  .vip_product_name {
    font-size: 24px;
    font-weight: 500;
    color: #333333;
  }
  .product_time {
  }
  .product_price {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;

    strong {
      font-size: 24px;
      font-weight: 500;
    }
  }
  .old_pirce {
    font-size: 12px;
    font-weight: 400;
    color: #838cb2;
    text-decoration: line-through;
    margin-bottom: 10px;
  }
  .product_tags {
  }
  .active_item {
    border: 2px solid #3f63ff;

    .vip_product_name {
      color: #3f63ff;
    }
    .product_price {
      color: #3f63ff;
    }
  }
}
ul {
  display: flex;
  justify-content: space-between;
  gap: 0 14px;
  padding-bottom: 10px;

  li {
    text-align: center;
    width: 183px;
    box-sizing: border-box;
    background: #f3f5ff;
    border-radius: 10px 10px 10px 10px;
    border: 2px solid #e5eaff;
    padding: 20px 20px;
    flex: none;
    cursor: pointer;
    &:hover {
      border: 2px solid #3f63ff;
    }
    .member_serve_year {
      font-size: 12px;
      font-weight: 500;
      color: #333333;
      line-height: 24px;

      strong {
        font-size: 18px;
      }
    }

    .member_serve_month {
      font-size: 10px;
      font-weight: 400;
      color: #838cb2;
      line-height: 12px;
      margin: 5px 0 6px;
    }

    .member_serve_btn {
      margin-top: 15px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      line-height: 33px;
      width: 131px;
      height: 33px;
      background: #3f63ff;
      border-radius: 5px 5px 5px 5px;
      border-image: linear-gradient(
          180deg,
          rgba(255, 255, 255, 1),
          rgba(0, 0, 0, 1)
        )
        0 0;
    }
  }
}

.member_equities {
  background: linear-gradient(180deg, #e5eaff 0%, #ffffff 100%);
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  padding: 22px 22px 37px;
  margin-bottom: 115px;

  img {
    width: 132px;
    margin-bottom: 20px;
  }

  table {
    width: 100%;
    text-align: center;

    thead {
      tr {
        font-weight: 500;
        color: #ffffff;
        line-height: 22px;
        background-color: #3f63ff;
        font-size: 18px;

        th {
          border-bottom: 1px solid #3f63ff;
          padding: 15px 0;
          border-right: 1px solid #e0e5f4;

          &:first-child {
            border-top-left-radius: 5px;
          }

          &:last-child {
            border-top-right-radius: 5px;
            border-right: none;
          }
        }
      }
    }

    tbody {
      border-radius: 0px 0px 5px 5px;

      tr {
        font-size: 18px;
        font-weight: 500;
        color: #333b5d;
        line-height: 22px;

        td {
          border-bottom: 1px solid #e0e5f4;
          border-left: 1px solid #e0e5f4;
          padding: 15px 0;

          &:first-child {
            border-bottom-left-radius: 5px;
          }

          &:last-child {
            border-right: 1px solid #e0e5f4;
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
}
.weixin_pay_box {
  .top_pay_title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
    .pay_icon {
      width: 40px;
      height: 40px;
      margin-right: 6px;
    }
    .pay_title {
      font-weight: bold;
      font-size: 30px;
      color: #333333;
    }
  }
  .er_code_box {
    width: 300px;
    height: 300px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>